import React from "react";
import "./Main.css";

function Main() {
  return (
    <div className="main-container">
      <div className="main-header">
        <h1>jonathanhobson.com</h1>
      </div>
      <div className="social-logo">
        <a href="https://www.linkedin.com/in/jonathanhobson" target="_blank" rel="noreferrer noopener">
          <img 
            src="https://img.icons8.com/ios-glyphs/480/FFFFFF/linkedin.png"
            style={{"width": "40px"}}
            alt="LinkedIn"
          />
        </a>
      </div>
    </div>
    
  );
}

export default Main;
